import React from 'react';
import Layout from '../layout';
import {
  Hero,
  WhoWeAre,
  OurCompany,
  WhyChoose,
  Products,
  Update,
  Partner,
  Testimonials,
} from '../views/features';

const home = ({ pageContext: { data = {}, metaData = {}, menus = [] } }) => {
  return (
    <Layout SEOTitle={data.label} metaData={metaData} menus={menus}>
      <Hero section={data.sections && data.sections[0]} />
      <WhoWeAre section={data.sections && data.sections[1]} />
      <OurCompany
        section={data.sections && data.sections[2]}
        nextSection={data.sections && data.sections[3]}
      />
      <WhyChoose section={data.sections && data.sections[3]} />
      <Products section={data.sections && data.sections[4]} />
      <Update />
      <Partner section={data.sections && data.sections[5]} />
      <Testimonials section={data.sections && data.sections[6]} />
    </Layout>
  );
};

export default home;
